/* -----------------------------------------------------------------------------
 *
 * Components: Return Visitor Modal
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Common
 *
 * This file is just a clearing-house.
 * Use this file to pull in common utilties into CSS Modules
 *
 * ----------------------------------------------------------------------------- */

/* Fonts */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Fonts
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Object Sans
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Regular.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Medium.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Bold.woff2' );
}

/* -----------------------------------------------------------------------------
 * Bandwidth
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'bandwidth';
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/bandwidth/BandwidthDisplay_Rg.woff2' );
}

/* Media Queries */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Media Queries
 *
 * ----------------------------------------------------------------------------- */

@custom-media --xsmall( min-width: 320px );

/* Admin */

/* Mixins */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Icomoon
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Grid
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Mixins: Backgrounds
 *
 * ----------------------------------------------------------------------------- */

/* Color utils */

.ReturnVisitorModal_u-color-blue__2TM34 {
  color: var( --color-electric-blue );
}

.ReturnVisitorModal_u-color-red__fksvW {
  color: var( --color-torch-red );
}

.ReturnVisitorModal_u-color-midnight__E6m0p {
  color: var( --color-midnight-express );
}

.ReturnVisitorModal_u-color-white__RZSHg {
  color: var( --color-white );
}

.ReturnVisitorModal_u-theme-white__24Xxa {
  background-color: var( --color-white );
  color: var( --color-black );
}

.ReturnVisitorModal_u-theme-light__nZtVO {
  background-color: var( --color-zircon );
}

.ReturnVisitorModal_u-theme-dark__DrlWf {
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.ReturnVisitorModal_u-theme-blue__35siR {
  background-color: var( --color-electric-blue );
}

.ReturnVisitorModal_u-theme-red__di_m_ {
  background-color: var( --color-torch-red );
  color: var( --color-white );
}

.ReturnVisitorModal_u-theme-black__rrjpb {
  background-color: var( --color-black );
  color: var( --color-white );
}

/* Visibility utils (taken from Foundation) */

@media print, screen and (max-width: 39.99875em) {
  .ReturnVisitorModal_hide-for-small-only__aqGI_ {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 40em) {
  .ReturnVisitorModal_show-for-small-only__tKU13 {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .ReturnVisitorModal_hide-for-medium__P_FU_ {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .ReturnVisitorModal_show-for-medium__L6tLL {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .ReturnVisitorModal_hide-for-medium-only__auy1i {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .ReturnVisitorModal_show-for-medium-only__5lxR_ {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .ReturnVisitorModal_hide-for-large__JggK6 {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .ReturnVisitorModal_show-for-large__GLJ0R {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .ReturnVisitorModal_hide-for-large-only__TIKMZ {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .ReturnVisitorModal_show-for-large-only__I7HVQ {
    display: none !important;
  }
}

.ReturnVisitorModal_component__orlTJ {
  padding: 0;
  text-align: center;
}

/* -----------------------------------------------------------------------------
 * Modal Container
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__modalContainer__UZaas {
  max-width: calc( var( --grid-width-default ) - (var( --grid-gutter ) * 2) );
  margin-top: 60px;
  position: relative;
  padding: 0;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__modalContainer__UZaas {
    max-width: var( --grid-width-default )
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__modalContainer__UZaas {
    margin-top: 0
}
  }

/* -----------------------------------------------------------------------------
 * Content Wrapper
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__contentWrapper__4jER4 {
  padding: 40px 24px;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__contentWrapper__4jER4 {
    padding: 80px 45px
}
  }

@media ( min-width: 1280px ) {

.ReturnVisitorModal_component__contentWrapper__4jER4 {
    padding: 80px 100px
}
  }

/* -----------------------------------------------------------------------------
 * Header
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__header__N7n2j {
  display: block;
  margin-bottom: 32px;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__header__N7n2j {
    margin-bottom: 56px
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__header__N7n2j {
    margin-bottom: 70px
}
  }

/* -----------------------------------------------------------------------------
 * Close
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__close__UD5ZF {
  position: absolute;
  right: var( --grid-gutter-half );
  font-size: 1.3125rem;
  margin-top: 5px;
}

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__title__ttfaH {
  margin-bottom: 24px;
  padding: 0;
  color: var(--color-torch-red);
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__title__ttfaH {
    margin-bottom: 24px
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__title__ttfaH {
    margin-bottom: 32px
}
  }

/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__subtitle___pyis {
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.22;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__subtitle___pyis {
    margin: 0 auto
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__subtitle___pyis {
    max-width: 70%
}
  }

/* -----------------------------------------------------------------------------
 * Body
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__body__1ZUVv {
  margin: 0;
}

/* -----------------------------------------------------------------------------
 * Blocks
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__blocks__wyk_G {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__blocks__wyk_G {
    flex-flow: row;
    align-items: stretch
}
  }

/* -----------------------------------------------------------------------------
 * Block
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__block___WL_K {
  display: flex;
  flex-flow: column;
  margin-bottom: 32px;
  color: var( --color-midnight-express );
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__block___WL_K {
    display: block;
    flex: 1;
    margin-right: 24px;
    margin-bottom: 0
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__block___WL_K {
    margin-right: 80px
}
  }

.ReturnVisitorModal_component__blocks__wyk_G .ReturnVisitorModal_component__block___WL_K:last-of-type {
  margin-bottom: 0;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__blocks__wyk_G .ReturnVisitorModal_component__block___WL_K:last-of-type {
    margin-right: 0
}
  }

/* -----------------------------------------------------------------------------
 * Block Title
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__blockTitle__aPa_G {
  padding: 0;
  margin-bottom: 16px;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__blockTitle__aPa_G {
    margin-bottom: 24px
}
  }

.ReturnVisitorModal_component__blockTitle__aPa_G button {
  padding: 0;
}

/* -----------------------------------------------------------------------------
 * Block Content
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__blockContent__Ivjhc {
  margin-bottom: 16px;
}

@media ( min-width: 768px ) {

.ReturnVisitorModal_component__blockContent__Ivjhc {
    margin-bottom: 40px;
    min-height: 83px
}
  }

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__blockContent__Ivjhc {
    min-height: 61px
}
  }

@media ( min-width: 1280px ) {

.ReturnVisitorModal_component__blockContent__Ivjhc {
    min-height: 81px
}
  }

@media ( min-width: 1440px ) {

.ReturnVisitorModal_component__blockContent__Ivjhc {
    min-height: 63px
}
  }

/* -----------------------------------------------------------------------------
 * Block Button
 * ----------------------------------------------------------------------------- */

.ReturnVisitorModal_component__blockBtn__8ZKMG {
  display: block;
  font-size: 0.875rem;
  line-height: 1.57;
  margin: 0;
}

@media ( min-width: 1024px ) {

.ReturnVisitorModal_component__blockBtn__8ZKMG {
    display: inline-block
}
  }

a.ReturnVisitorModal_component__blockBtn__8ZKMG[ href^='tel' ] {
  color: var( --color-white );
}

/* Spinning loader animation from https://loading.io/css/ */
.Loader_loader__BnQr1 {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.Loader_loader__BnQr1 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid var( --color-midnight-express );
  border-radius: 50%;
  animation: Loader_loader__BnQr1 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var( --color-midnight-express ) transparent transparent transparent;
}
.Loader_loader__BnQr1 div:nth-child(1) {
  animation-delay: -0.45s;
}
.Loader_loader__BnQr1 div:nth-child(2) {
  animation-delay: -0.3s;
}
.Loader_loader__BnQr1 div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes Loader_loader__BnQr1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ResumeQuoteModal_component__modalContainer__2_Iz3 {
  width: var( --grid-width-default );
  padding: 0;
  border-radius: 24px;
}

.ResumeQuoteModal_component__close__47jV5 {
  position: absolute;
  right: 0;
  margin: 26px 27px 0 0 ;
}

.ResumeQuoteModal_component__close__47jV5::before {
  font-size: 24px;
  font-weight: 700;
}

.ResumeQuoteModal_component__contentWrapper__nbzCv {
  text-align: center;
  padding: 80px 0;
}

@media ( max-width: 767px ) {

.ResumeQuoteModal_component__contentWrapper__nbzCv {
    padding-left: 24px;
    padding-right: 24px
}
  }

.ResumeQuoteModal_component__title__SevOq {
  margin: 0 0 16px 0;
  padding: 0;
}

@media ( min-width: 768px ) {

.ResumeQuoteModal_component__title__SevOq {
    font-size: 1.875rem;
    line-height: 1.13
}
  }

.ResumeQuoteModal_component__subtitle__GLGSP {
  font-weight: 500;
}

.ResumeQuoteModal_component__addressWrapper__rsMD7 {
  margin: 42px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

@media ( max-width: 767px ) {

.ResumeQuoteModal_component__addressWrapper__rsMD7 {
    flex-wrap: wrap;
    margin: 34px 0
}
  }

.ResumeQuoteModal_component__icon__fbqQc {
  font-size: 24px;
  margin-right: 8px;
}

.ResumeQuoteModal_component__address__1baZy {
  margin: 0 0 0 8px;
  text-transform: uppercase;
}

@media ( max-width: 767px ) {

.ResumeQuoteModal_component__address__1baZy {
    margin: 10px 0 0
}
  }

.ResumeQuoteModal_component__buttonWrapper__marJV {
  width: 265px;
  margin: 0 auto;
}
/* -----------------------------------------------------------------------------
 *
 * Components: Hero Address Check
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Common
 *
 * This file is just a clearing-house.
 * Use this file to pull in common utilties into CSS Modules
 *
 * ----------------------------------------------------------------------------- */

/* Fonts */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Fonts
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Object Sans
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Regular.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Medium.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Bold.woff2' );
}

/* -----------------------------------------------------------------------------
 * Bandwidth
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'bandwidth';
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/bandwidth/BandwidthDisplay_Rg.woff2' );
}

/* Media Queries */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Media Queries
 *
 * ----------------------------------------------------------------------------- */

@custom-media --xsmall( min-width: 320px );

/* Admin */

/* Mixins */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Icomoon
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Grid
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Mixins: Backgrounds
 *
 * ----------------------------------------------------------------------------- */

/* Color utils */

.HeroAddressCheck5G_u-color-blue__jCrpI {
  color: var( --color-electric-blue );
}

.HeroAddressCheck5G_u-color-red__Tve9d {
  color: var( --color-torch-red );
}

.HeroAddressCheck5G_u-color-midnight__OD8h2 {
  color: var( --color-midnight-express );
}

.HeroAddressCheck5G_u-color-white__8lmRV {
  color: var( --color-white );
}

.HeroAddressCheck5G_u-theme-white__EGHCX {
  background-color: var( --color-white );
  color: var( --color-black );
}

.HeroAddressCheck5G_u-theme-light__AtvwT {
  background-color: var( --color-zircon );
}

.HeroAddressCheck5G_u-theme-dark__4gkaO {
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.HeroAddressCheck5G_u-theme-blue__Z4P_p {
  background-color: var( --color-electric-blue );
}

.HeroAddressCheck5G_u-theme-red__Z3XaW {
  background-color: var( --color-torch-red );
  color: var( --color-white );
}

.HeroAddressCheck5G_u-theme-black__AqbeR {
  background-color: var( --color-black );
  color: var( --color-white );
}

/* Visibility utils (taken from Foundation) */

@media print, screen and (max-width: 39.99875em) {
  .HeroAddressCheck5G_hide-for-small-only__ZPzlf {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 40em) {
  .HeroAddressCheck5G_show-for-small-only__m9RHp {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .HeroAddressCheck5G_hide-for-medium__7yeXW {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .HeroAddressCheck5G_show-for-medium__h0s6o {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .HeroAddressCheck5G_hide-for-medium-only__sfOZy {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .HeroAddressCheck5G_show-for-medium-only__r9gZw {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .HeroAddressCheck5G_hide-for-large__KV5dk {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .HeroAddressCheck5G_show-for-large__PYLwy {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .HeroAddressCheck5G_hide-for-large-only__kCDj1 {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .HeroAddressCheck5G_show-for-large-only__l5_Iz {
    display: none !important;
  }
}

@media ( min-width: 768px ) {

.HeroAddressCheck5G_component__DjCll {
    background-position-x: 100%
}
  }

/* override */

.HeroAddressCheck5G_component__DjCll .HeroAddressCheck5G_component__wrapper__WMg1U {
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.HeroAddressCheck5G_component__DjCll .HeroAddressCheck5G_component__layout__Rdc0N {
  padding-right: 0;
  padding-left: 0;
}

.HeroAddressCheck5G_component__ctaContainer__4T61w {
  max-width: 800px;
  margin-left: 35px;
  padding-right: 35px;
}

@media ( min-width: 768px ) {

.HeroAddressCheck5G_component__ctaContainer__4T61w {
    margin-left: 50px
}
  }

@media ( min-width: 1280px ) {

.HeroAddressCheck5G_component__ctaContainer__4T61w {
    margin-left: 165px
}
  }

@media (min-width: 1600px) {

.HeroAddressCheck5G_component__ctaContainer__4T61w {
    margin-left: 345px
}
  }

.HeroAddressCheck5G_component__mobileBackground__3h6Ks {
  width: 376px;
  margin-right: auto;
  margin-left: auto;
  transform: none;
}

/* -----------------------------------------------------------------------------
 *
 * Component: Split Section
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Common
 *
 * This file is just a clearing-house.
 * Use this file to pull in common utilties into CSS Modules
 *
 * ----------------------------------------------------------------------------- */

/* Fonts */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Fonts
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Object Sans
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Regular.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Medium.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Bold.woff2' );
}

/* -----------------------------------------------------------------------------
 * Bandwidth
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'bandwidth';
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/bandwidth/BandwidthDisplay_Rg.woff2' );
}

/* Media Queries */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Media Queries
 *
 * ----------------------------------------------------------------------------- */

@custom-media --xsmall( min-width: 320px );

/* Admin */

/* Mixins */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Icomoon
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Grid
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Mixins: Backgrounds
 *
 * ----------------------------------------------------------------------------- */

/* Color utils */

.SplitSection_u-color-blue__c1hvT {
  color: var( --color-electric-blue );
}

.SplitSection_u-color-red__iZ4st {
  color: var( --color-torch-red );
}

.SplitSection_u-color-midnight__mRYnB {
  color: var( --color-midnight-express );
}

.SplitSection_u-color-white__vNlVS {
  color: var( --color-white );
}

.SplitSection_u-theme-white__bfOkS {
  background-color: var( --color-white );
  color: var( --color-black );
}

.SplitSection_u-theme-light__AJbdl {
  background-color: var( --color-zircon );
}

.SplitSection_u-theme-dark__R6AiO {
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.SplitSection_u-theme-blue__68_x7 {
  background-color: var( --color-electric-blue );
}

.SplitSection_u-theme-red__q10mf {
  background-color: var( --color-torch-red );
  color: var( --color-white );
}

.SplitSection_u-theme-black__7etZu {
  background-color: var( --color-black );
  color: var( --color-white );
}

/* Visibility utils (taken from Foundation) */

@media print, screen and (max-width: 39.99875em) {
  .SplitSection_hide-for-small-only__A8MHr {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 40em) {
  .SplitSection_show-for-small-only__nN3JR {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .SplitSection_hide-for-medium__6Uf3x {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .SplitSection_show-for-medium__NZ34q {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .SplitSection_hide-for-medium-only__ixj0F {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .SplitSection_show-for-medium-only__tmfJh {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .SplitSection_hide-for-large__YMqOP {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .SplitSection_show-for-large__aOgpR {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .SplitSection_hide-for-large-only__uFjyI {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .SplitSection_show-for-large-only__OuVAS {
    display: none !important;
  }
}

.SplitSection_component__MMIEc {
  display: flex;
  position: relative;
  flex-flow: column-reverse;
  padding: 0;
  overflow-x: hidden;
}

.SplitSection_componentMobileReverse__Jb1Pm {
  flex-flow: column;
}

/* -----------------------------------------------------------------------------
 * Content
 * ----------------------------------------------------------------------------- */

.SplitSection_component__content__0aeDB {
  padding: 60px 0;
}

@media ( min-width: 1024px ) {

.SplitSection_component__content__0aeDB {
    padding: 80px 0;
    padding-left: 32.5px
}
  }

@media ( min-width: 1024px ) {

.SplitSection_component__contentReverse__pAC_N {
    padding-right: 32.5px;
    padding-left: 0
}
  }

.SplitSection_component__copyContainer__4yLe_ {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.SplitSection_component__copy__qCcJc {
  margin-bottom: 0;
}

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.SplitSection_component__title__6pP0q {
  margin-bottom: 15px;
  padding: 0;
  font-weight: 700;
}

@media ( min-width: 768px ) {

.SplitSection_component__title__6pP0q span {
    font-size: 2.1875rem;
    line-height: 1.43
}
  }

/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */

.SplitSection_component__subtitle__5AriX {
  margin-bottom: 30px;
}

/* -----------------------------------------------------------------------------
 * Item heading legal
 * ----------------------------------------------------------------------------- */

.SplitSection_component__itemHeading__FDHdZ sup {
  position: relative;
  top: -10px;
  font-size: 50%;
}

/* -----------------------------------------------------------------------------
 * List
 * ----------------------------------------------------------------------------- */

.SplitSection_component__list__xTFMT {
  margin-bottom: 30px;
  padding: 0;
  list-style-type: none;
  line-height: 1.25;
}

.SplitSection_component__list__xTFMT li {
  position: relative;
  margin-bottom: 20px;
}

/* -----------------------------------------------------------------------------
 * Icon
 * ----------------------------------------------------------------------------- */

.SplitSection_component__icon__7QVUP {
  margin-right: 10px;
  color: var( --color-primary );
  font-size: 1.0625rem;
}

.SplitSection_component__icon__red__qP3So,
.SplitSection_component__icon__dark__h_77t {
  color: var( --color-electric-blue );
}

/* -----------------------------------------------------------------------------
 * Legal
 * ----------------------------------------------------------------------------- */

.SplitSection_component__legal__P0dTA {
  margin-bottom: 40px;
}

/* -----------------------------------------------------------------------------
 * Legal
 * ----------------------------------------------------------------------------- */

@media ( min-width: 1024px ) {

.SplitSection_component__buttonStyle__abgY8 {
    width: 100%
}
  }

@media ( min-width: 1440px ) {

.SplitSection_component__buttonStyle__abgY8 {
    width: 48%
}
  }

/* -----------------------------------------------------------------------------
 * Background Wrap
 * ----------------------------------------------------------------------------- */

.SplitSection_component__backgroundWrap__qoAa_ {
  position: relative;
  width: 100%;
  height: 100%;
}

@media ( min-width: 768px ) {

.SplitSection_component__backgroundWrap__qoAa_ {
    display: block;
    width: 50vw;
}
  }

@media ( max-width: 767px ) {
    .SplitSection_component__backgroundWrap__qoAa_ img {
      object-fit: cover !important;
      object-position: center !important;
    }
  }

/* @media (--hd) {
    max-width: 100%;
  } */

.SplitSection_component__backgroundWrap__qoAa_ img {
    object-fit: cover;
    object-position: center;
  }

@media ( min-width: 768px ) {

.SplitSection_component__backgroundWrap__qoAa_ {
    position: absolute;
    right: var( --grid-gutter );
}
  }

.SplitSection_component__backgroundWrap__qoAa_ {

  right: 0;
  overflow: hidden;
}

.SplitSection_component__backgroundWrapReverse__gMP1n {
  left: 0;
}

.SplitSection_component__backgroundWrapContain__t8DUF img {
  object-fit: contain !important;
}

/*
.component__backgroundWrapMobile {
  display: block;
}

.component__backgroundWrap img {
  @media (--xlarge) {
    object-fit: contain;
  }
} */

/* -----------------------------------------------------------------------------
 * Background
 * ----------------------------------------------------------------------------- */

.SplitSection_component__background__lbyWS {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.SplitSection_component__background_img__ABTl3 {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

/* -----------------------------------------------------------------------------
 * Background Mobile
 * ----------------------------------------------------------------------------- */

.SplitSection_component__backgroundMobile__cIqwI {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 230px;
}

@media ( min-width: 576px ) {

.SplitSection_component__backgroundMobile__cIqwI {
    height: 414px
}
  }

@media ( min-width: 768px ) {

.SplitSection_component__backgroundMobile__cIqwI {
    display: none
}
  }

.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{position:relative;background-color:#333;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;white-space:normal;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}
/* -----------------------------------------------------------------------------
 *
 * Components: FloatingPanel
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Common
 *
 * This file is just a clearing-house.
 * Use this file to pull in common utilties into CSS Modules
 *
 * ----------------------------------------------------------------------------- */

/* Fonts */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Fonts
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Object Sans
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Regular.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Medium.woff2' );
}

@font-face {
  font-family: 'object-sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/object-sans/PPObjectSans-Bold.woff2' );
}

/* -----------------------------------------------------------------------------
 * Bandwidth
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'bandwidth';
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: url( 'https://tundra.frontier.redventures.io/migration/fonts/bandwidth/BandwidthDisplay_Rg.woff2' );
}

/* Media Queries */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Media Queries
 *
 * ----------------------------------------------------------------------------- */

@custom-media --xsmall( min-width: 320px );

/* Admin */

/* Mixins */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Icomoon
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Utilities: Grid
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 *
 * Mixins: Backgrounds
 *
 * ----------------------------------------------------------------------------- */

/* Color utils */

.FloatingPanel_u-color-blue__uKZyK {
  color: var( --color-electric-blue );
}

.FloatingPanel_u-color-red__znrwX {
  color: var( --color-torch-red );
}

.FloatingPanel_u-color-midnight__4XsJg {
  color: var( --color-midnight-express );
}

.FloatingPanel_u-color-white__bH102 {
  color: var( --color-white );
}

.FloatingPanel_u-theme-white__01Zl3 {
  background-color: var( --color-white );
  color: var( --color-black );
}

.FloatingPanel_u-theme-light__6av8d {
  background-color: var( --color-zircon );
}

.FloatingPanel_u-theme-dark__cUvOw {
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

.FloatingPanel_u-theme-blue__Kj7mE {
  background-color: var( --color-electric-blue );
}

.FloatingPanel_u-theme-red__7NIT8 {
  background-color: var( --color-torch-red );
  color: var( --color-white );
}

.FloatingPanel_u-theme-black__Egvrj {
  background-color: var( --color-black );
  color: var( --color-white );
}

/* Visibility utils (taken from Foundation) */

@media print, screen and (max-width: 39.99875em) {
  .FloatingPanel_hide-for-small-only__q0v5Z {
    display: none !important;
  }
}

@media screen and (max-width: 0), screen and (min-width: 40em) {
  .FloatingPanel_show-for-small-only__gK8xl {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .FloatingPanel_hide-for-medium__K5bcM {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em) {
  .FloatingPanel_show-for-medium__WuKtN {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .FloatingPanel_hide-for-medium-only__K_AKS {
    display: none !important;
  }
}

@media screen and (max-width: 39.99875em), screen and (min-width: 64em) {
  .FloatingPanel_show-for-medium-only__eVU1L {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .FloatingPanel_hide-for-large__HO_mC {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .FloatingPanel_show-for-large__ecC67 {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 74.99875em) {
  .FloatingPanel_hide-for-large-only__aYLzZ {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 75em) {
  .FloatingPanel_show-for-large-only__MtoBM {
    display: none !important;
  }
}

.FloatingPanel_component__ZQctw {
  padding-top: 30px;
  padding-bottom: 22px;
  background-color: var( --color-white );
  background-repeat: no-repeat;
  background-position: bottom 38px right 0;
  background-size: 100% 44%;
}

@media ( min-width: 768px ) {

.FloatingPanel_component__ZQctw {
    background-position: bottom 88px right 0;
    background-size: 100% 46%
}
  }

@media ( min-width: 1024px ) {

.FloatingPanel_component__ZQctw {
    background-position: bottom 93px right 0
}
  }

@media ( min-width: 1440px ) {

.FloatingPanel_component__ZQctw {
    padding-bottom: 40px;
    background-position: bottom -8px right 0;
    background-size: 100vw 112%
}
  }

/* -----------------------------------------------------------------------------
 * Row
 * ----------------------------------------------------------------------------- */

@media ( min-width: 768px ) {

.FloatingPanel_row__2gByJ {
    justify-content: center;
    margin-right: auto;
    margin-left: auto
}
  }

@media ( min-width: 1440px ) {

.FloatingPanel_row__2gByJ {
    align-items: center;
    justify-content: space-between
}
  }

/* -----------------------------------------------------------------------------
 * Panel
 * ----------------------------------------------------------------------------- */

.FloatingPanel_panel__8gfM6 {
  width: 100%;
  margin-bottom: 6px;
  padding: 40px 24px;
  border-radius: 32px;
  background-color: var( --color-midnight-express );
  color: var( --color-white );
}

@media ( min-width: 768px ) {

.FloatingPanel_panel__8gfM6 {
    padding: 48px
}
  }

@media ( min-width: 1440px ) {

.FloatingPanel_panel__8gfM6 {
    max-width: 600px;
    margin-bottom: 0;
    padding: 88px 48px
}
  }

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.FloatingPanel_title__kmIh5 {
  padding-top: 0;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.13;
}

@media ( min-width: 768px ) {

.FloatingPanel_title__kmIh5 {
    margin-bottom: 28px
}
  }

@media ( min-width: 1440px ) {

.FloatingPanel_title__kmIh5 {
    margin-bottom: 8px;
    font-size: 3.5rem;
    line-height: 1.07
}
  }

/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */

.FloatingPanel_subtitle__0kRPf {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.22;
}

@media ( min-width: 768px ) {

.FloatingPanel_subtitle__0kRPf {
    margin-bottom: 28px
}
  }

/* -----------------------------------------------------------------------------
 * UL
 * ----------------------------------------------------------------------------- */

.FloatingPanel_ul__zVP02 {
  list-style-type: none;
}

/* -----------------------------------------------------------------------------
 * LI
 * ----------------------------------------------------------------------------- */

.FloatingPanel_li__Z9T3M {
  position: relative;
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.25;
}

.FloatingPanel_li__Z9T3M:last-of-type {
  margin-bottom: 40px;
}

.FloatingPanel_li__Z9T3M::before {
  font-family: 'icomoon' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  speak: none;

  content: var( --icon-check-short );
  position: absolute;
  top: 1px;
  margin-left: -30px;
  color: var( --color-electric-blue );
  font-size: 1.125rem;
}

/* -----------------------------------------------------------------------------
 * Image Container
 * ----------------------------------------------------------------------------- */

@media ( min-width: 1440px ) {

.FloatingPanel_imageContainer__UwAcI {
    transform: translateX( 100px )
}
  }

